const MESSAGE = {
  EN: {
    zone_setting_subleftbar: 'Zone Setting',
    camera_subleftbar: 'Camera',
    active_subleftbar: 'Active',
    sync_info_subleftbar: 'Automatically sync with system when connected to internet.',
    last_sync_subleftbar: 'Last Sync: Today',
    now_sync_subleftbar: 'Now Syncing',
    subleftbar_footer: 'Best viewed in latest 2 versions of Google Chrome.',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    zone_setting_subleftbar: 'การตั้งค่าโซน',
    camera_subleftbar: 'กล้อง',
    active_subleftbar: 'ออนไลน์',
    sync_info_subleftbar: 'ซิงค์อัตโนมัติกับระบบเมื่อเชื่อมต่อกับอินเทอร์เน็ต',
    last_sync_subleftbar: 'เชื่อมต่อล่าสุด วันนี้',
    now_sync_subleftbar: 'กำลังซิงค์',
    subleftbar_footer: 'แสดงผลได้ดีที่สุดใน 2 เวอร์ชันล่าสุดของ Google Chrome',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE

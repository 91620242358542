import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as violationDetailActions from '../ViolationDetailPage/actions'
import * as SelectSiteActions from '../SelectSitePage/actions'
import * as ProjectDetailActions from '../ProjectDetailPage/actions'

import { Route, Switch } from 'react-router-dom'
import AllCameraZonePage from '../AllCameraZonePage/Loadable'
import EachCameraZonePage from '../EachCameraZonePage/Loadable'
import SubLeftbarWrapper from '../../components/SubLeftbarWrapper/Loadable'
import DivideLine from '../../components/DivideLine/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import PageWrapper from '../../components/PageWrapper/Loadable'
import CollapseIcon from '../../asset/images/button/collapse.svg'
import ExpandIcon from '../../asset/images/button/expand.svg'

import fetchErrorHandler from '../../utils/fetchErrorHandler'
import { detectBrowser } from '../../utils'
import CameraZoneStyled from './styledComponent'
import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FRONTEND_ROUTES,
  THAI_LANGUAGE,
  CAMERA_ACTIVE,
  CAMERA_INACTIVE
} from '../../utils'
import MESSAGE from './message'

class CameraZonePage extends React.Component {
  state = {
    subLeftBarCollapse: false,
    lastSyncTime: moment(),
    failureModalData: {
      show: false,
      title: '',
      message: '',
      onPrimaryButtonClick: undefined,
      primaryButtonText: ''
    }
  }

  async componentDidMount() {
    const projectID = this.props.match.params.projectID
    await this.props.setCurrentProjectCookie(projectID)
    this.loadListSite()
    this._loadCameraList()
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleFetchLoadCameraList(prevProps)
    this.handleFetchSyncCameraList(prevProps)
    this.handleFetchLoadListSite(prevProps)
    this.handleFetchSyncCameraZoneList(prevProps)
  }

  handleFetchSyncCameraZoneList(prevProps) {
    if (
      prevProps.allCameraZonePage.loadListZone.syncStatus === FETCH_STATUS_REQUEST &&
      this.props.allCameraZonePage.loadListZone.syncStatus === FETCH_STATUS_SUCCESS
    ) {
      this.resetSyncTime()
    }
  }

  handleFetchLoadCameraList(prevProps) {
    if (prevProps.loadCameraListStatus.fetchStatus === FETCH_STATUS_REQUEST && this.props.loadCameraListStatus.fetchStatus === FETCH_STATUS_FAILURE) {
      this.handleLoadCameraListFailure()
    }
  }

  handleFetchSyncCameraList(prevProps) {
    if (prevProps.loadCameraListStatus.syncStatus === FETCH_STATUS_REQUEST && this.props.loadCameraListStatus.syncStatus === FETCH_STATUS_FAILURE) {
      this.handleLoadCameraListFailure()
    }
  }

  handleFetchLoadListSite(prevProps) {
    if (prevProps.loadSiteStatus.fetchStatus === FETCH_STATUS_REQUEST && this.props.loadSiteStatus.fetchStatus === FETCH_STATUS_FAILURE) {
      this.handleLoadListSiteFailure()
    }
  }

  handleLoadListSiteFailure() {
    const statusCode = this.props.loadSiteStatus.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  handleLoadCameraListFailure() {
    const statusCode = this.props.loadCameraListStatus.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  forceLogin() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }
  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  resetSyncTime() {
    this.setState({
      lastSyncTime: moment()
    })
  }

  _loadCameraList() {
    const siteId = this.props.match.params.siteId
    this.props.loadCameraList(siteId)
  }

  loadListSite() {
    this.props.getListSite()
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language || THAI_LANGUAGE]
    return msg[property]
  }

  getOnlineCamera() {
    return this.props.cameraList.filter(
      (camera) => camera.camera_status.toUpperCase() === CAMERA_ACTIVE || camera.camera_status.toUpperCase() === CAMERA_INACTIVE
    ).length
  }

  getSubLeftbarTitle() {
    let titleClassName = detectBrowser() === 'Firefox' ? ' is-firefox' : ''
    return <div className={'left-bar-header text-style' + titleClassName}>{this.getMessage('zone_setting_subleftbar')}</div>
  }

  getSubLeftbarSubTitle() {
    return (
      <div className="left-bar-sub-header text-style">
        {`${this.getMessage('camera_subleftbar')} ${this.getOnlineCamera()}/${this.props.cameraList.length} ${this.getMessage('active_subleftbar')}`}
      </div>
    )
  }

  collapseSubLeftBar() {
    this.setState({
      subLeftBarCollapse: true
    })
  }

  expandSubLeftBar() {
    this.setState({
      subLeftBarCollapse: false
    })
  }

  renderCollapseButton() {
    return (
      <div className="collapse-button" onClick={() => this.collapseSubLeftBar()}>
        <img src={CollapseIcon} alt="" />
      </div>
    )
  }

  renderExpandButton() {
    return (
      <div className="expand-button" onClick={() => this.expandSubLeftBar()}>
        <img src={ExpandIcon} alt="" />
      </div>
    )
  }

  getSyncText() {
    const { syncStatus } = this.props.loadCameraListStatus
    let message = `${this.getMessage('last_sync_subleftbar')} ${this.state.lastSyncTime.format('HH:mm:ss')}`
    if (syncStatus === FETCH_STATUS_REQUEST) {
      message = `${this.getMessage('now_sync_subleftbar')}...`
    }
    return message
  }

  getSubLeftbarFooter() {
    const latestYear = new Date().getFullYear()
    return (
      <div className="left-bar-footer">
        <div className="message text-style">{this.getMessage('sync_info_subleftbar')}</div>
        <div className="sync-container text-style">{this.getSyncText()}</div>
        <div className="footer-zone">
          <DivideLine className="footer-line" />
          <div className="footer-msg text-style">
            © {latestYear} Sertis Co., Ltd. <br />
            {this.getMessage('subleftbar_footer')}
          </div>
        </div>
      </div>
    )
  }

  getSubLeftbar() {
    if (this.state.subLeftBarCollapse) {
      return <SubLeftbarWrapper className="collapse">{this.renderExpandButton()}</SubLeftbarWrapper>
    } else {
      return (
        <SubLeftbarWrapper className="dashboard-left-bar">
          {this.getSubLeftbarTitle()}
          {this.renderCollapseButton()}
          {this.getSubLeftbarSubTitle()}
          <DivideLine className="leftbar-line" />
          {this.getSubLeftbarFooter()}
        </SubLeftbarWrapper>
      )
    }
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <CameraZoneStyled>
        <PageWrapper subLeftbar={this.getSubLeftbar()} subLeftBarCollapse={this.state.subLeftBarCollapse}>
          <Switch>
            <Route exact path={`${FRONTEND_ROUTES.selectSiteRoute}/:projectID/:siteId/zone`} component={AllCameraZonePage} />
            <Route path={`${FRONTEND_ROUTES.selectSiteRoute}/:projectID/:siteId/zone/:id`} component={EachCameraZonePage} />
          </Switch>
        </PageWrapper>
        {this.getFailureModal()}
      </CameraZoneStyled>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    applicationPage: state.applicationPage,
    allCameraZonePage: state.allCameraZonePage,
    loadSiteStatus: state.selectSitePage.loadSiteStatus,
    loadCameraListStatus: state.violationDetailPage.loadCameraListStatus,
    syncCameraZoneListStatus: state.allCameraZonePage.loadListZone.syncStatus,
    cameraList: state.violationDetailPage.cameraList,
    currentUser: state.loginPage.loginAPI.userData,
    date: state.applicationPage.date
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, violationDetailActions, SelectSiteActions, ProjectDetailActions), dispatch)
}

CameraZonePage.propTypes = {
  cameraList: PropTypes.array,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    language: PropTypes.string
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(CameraZonePage)
